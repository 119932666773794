.loading-screen {
    /* padding-top: 1rem; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fed51b;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading-spinner {
    border: 7px solid #000000;
    border-top: 7px solid hsl(30, 90%, 68%);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
