/* General */

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

div {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

th {
    font-weight: 400;
}

td {
    font-size: 16px;
    vertical-align: middle !important;
}

span {
    vertical-align: middle !important;
}

body::-webkit-scrollbar {
    display: none;
}

[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

[type="radio"] + img {
    cursor: pointer;
    filter: grayscale(1);
}

[type="radio"]:checked + img {
    filter: grayscale(0);
}

/* Font Sizes */

.fs-small {
    font-size: 14px;
}

.fs-large {
    font-size: 18px;
}

/* General Classes */

.pageContainer {
    padding-top: 1rem;
}

.contentContainer {
    padding-top: 1rem;
    min-height: calc(100vh - 171px);
}

.footer {
    position: relative;
    left: 0;
    bottom: 0;
}

.link {
    text-decoration: none;
    color: black;
}

.table thead tr {
    pointer-events: none;
}

tr.no-border th {
    border: 0;
}

.no-display {
    display: none;
}

.btn-avisporra {
    color: white !important;
    background-color: #003566ed !important;
    width: 100%;
    margin-top: 0.5em;
    display: block;
}

.btn-avisporra:hover {
    color: white !important;
    background-color: #003566 !important;
}

/* Navbar Classes */

.navbar-link {
    text-decoration: none;
    color: grey;
    padding: 10px;
}

.navbar-link.active {
    font-weight: bold;
    color: #003566;
    background-color: transparent;
}

.navbar-link:hover {
    text-decoration: none;
    color: #003566;
}

.dropdown-item,
.dropdown-item.active,
.dropdown-item.hover {
    padding: 10px 20px 10px 20px;
}

.logoEMShrunk {
    height: 50px;
    transition: all 0.5s ease-in-out;
}

.logoEM {
    height: 65px;
    transition: all 0.5s ease-in-out;
}

/* ID */

#rankingContainer {
    width: 30em;
}

#rankingTable {
    margin-top: 2rem;
}

#rankingSearch {
    margin: auto;
    padding: 0px 1rem 0px 1rem;
}

#updateTime {
    padding-bottom: 1rem;
    font-size: 12px;
}

#languagesSelection {
    margin-right: 40px;
}

#userNameAlert {
    padding-top: 1rem;
}

#serverErrorAlert h4,
#noBetsWarning h4,
p {
    display: inline;
}

#pageHeaderText {
    padding-bottom: 0rem;
    vertical-align: middle;
}

#serverErrorText,
#noBetsWarningText {
    padding-left: 20px;
    font-size: 18px;
}

#excelDownloadButton,
#uploadButton {
    padding-top: 20px;
}

img#donate-button {
    height: 3.5rem;
}

#favButton {
    box-shadow: none;
    border: none;
}

#cardDeadline {
    background-color: #ffd30c !important;
    color: black;
    font-weight: bold;
}

/* Specific Classes */

.card {
    background-color: white !important;
}

.card.bg-avisporra {
    border-color: #00356677 !important;
}

.card.bg-avisporra .card-subtitle,
.card.bg-avisporra .card-title {
    color: #003566;
}

.card-header {
    background-color: #0035660f;
}

.bg-info .card-header {
    background-color: #003566 !important;
    color: white !important;
}

.bg-info .card-footer {
    background-color: #003566 !important;
    color: white !important;
}

.card.bg-important {
    border-color: #e30713 !important;
}

.bg-important .card-header {
    background-color: #e30713 !important;
    color: white !important;
}

.bg-important .card-footer {
    background-color: #e30713 !important;
    color: white !important;
}

.spinner {
    margin-top: 15%;
    margin-left: 50%;
}

.accordion-button {
    display: block !important;
}

.accordionText {
    display: block !important;
    text-align: right !important;
    flex-grow: 2;
}

.languageFlag {
    margin: 5px;
}

.languageFlag:hover {
    cursor: pointer;
}

.form-control {
    border-width: 2px;
}

hr.dashed {
    border-top: 2px dashed #003566;
}

.dropDownSelected a#basic-nav-dropdown {
    color: #003566;
    font-weight: bold;
}

.text-ret-500 {
    color: rgb(239 68 68);
}
