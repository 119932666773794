.donationContainer {
    text-align: center;
    padding-top: 1rem;
}

.imgInDonationButton {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}

.donationButton {
    width: 100%;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    min-width: 8rem;
    text-align: center;
    text-decoration: none;
    transition:
        color 0.2s ease 0s,
        background-color 0.2s ease 0s,
        border-color 0.2s ease 0s,
        box-shadow 0.2s ease 0s;
    border: 0.125rem solid rgb(255, 209, 64);
    font-family: PayPalOpen-Bold, "Helvetica Neue", Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: bold;
    padding: 0.7rem 10rem;
    background: rgb(255, 209, 64);
    color: rgb(0, 48, 135);
}
.donationButton:hover {
    background: rgb(255, 232, 136);
    border-color: rgb(255, 232, 136);
}
.donationButton:active {
    background: rgb(255, 145, 0);
    border-color: rgb(255, 145, 0);
}
.donationButton:focus::after {
    content: "";
    position: absolute;
    top: -0.125rem;
    left: -0.125rem;
    border: 0.1875rem solid rgb(9, 127, 245);
    box-shadow: rgba(209, 229, 249, 0.9) 0px 0px 0px 0.375rem;
    border-radius: inherit;
    text-indent: -0.125rem;
    width: calc(100% - -0.25rem);
    height: calc(100% - -0.25rem);
    pointer-events: none;
}

@media only screen and (max-width: 1200px) {
    .donationButton {
        padding: 0.7rem 1rem;
    }
}
