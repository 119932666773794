.btn-group-xs > .btn,
.btn-xs {
    padding: 0.35rem 0.4rem 0.25rem 0.4rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 0.2rem;
}

.switch {
    position: relative;
    overflow: hidden;
}

.switch input[type="checkbox"] {
    display: none;
}

.switch-group {
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left 0.35s;
    -webkit-transition: left 0.35s;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.switch.off .switch-group {
    left: -100%;
}

.switch-on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 0;
}

.switch-off {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

.switch-handle {
    position: relative;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0.5rem;
    height: 100%;
    width: 0px;
    border-width: 0 1px;
    background-color: #fff;
    border-radius: 2px;
}

.btn-avisporra-blue,
.btn-avisporra-blue:hover {
    border-color: #003566;
    background-color: #003566;
    color: white;
}

.btn-avisporra-yellow,
.btn-avisporra-yellow:hover {
    border-color: #ffc300;
    background-color: #ffc300;
    color: black;
}

.switch.btn {
    min-width: 3.7rem;
    min-height: calc(1.5em + 0.75rem + 2px);
}

.switch.btn-lg {
    min-width: 5rem;
    line-height: 1.5;
    min-height: calc(1.5em + 1rem + 2px);
}

.switch-on.btn-lg {
    padding-right: 2rem;
}

.switch-off.btn-lg {
    padding-left: 2rem;
}

.switch-handle.btn-lg {
    width: 2.5rem;
}

.switch.btn-sm {
    min-width: 3.25rem;
    min-height: calc(1.5em + 0.5rem + 2px);
}

.switch-on.btn-sm {
    padding-right: 1rem;
}

.switch-off.btn-sm {
    padding-left: 1rem;
}

/* `xs` (bootstrap-3.3 - .btn - h:22px) */
.switch.btn-xs {
    min-width: 3.125rem;
    min-height: 1.375rem;
}

.switch-on.btn-xs {
    padding-right: 0.8rem;
}

.switch-off.btn-xs {
    padding-left: 0.8rem;
}
