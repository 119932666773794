.stages-wrapper .stage .stage-panel {
    float: left;
}

.stage.round-of-sixteen .stage-panel {
    width: 12.5%;
}

.stage.quarter-final .stage-panel {
    width: 25%;
}

.stage.semi-final .stage-panel {
    width: 50%;
}

.stage.final .stage-panel {
    width: 50%;
    margin: auto;
    float: none;
}

.stages-wrapper {
    margin: 0 0 35px;
}

.panel-heading,
.panel-body {
    text-align: center;
    padding: 5px 3px 5px;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid #d3d3d3;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.team-h {
    padding-top: 2px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d3d3d3;
}

.team-h .team-name {
    padding-bottom: 3px;
}

.team-v {
    padding-top: 5px;
    padding-bottom: 2px;
}

.team-v .team-name {
    padding-top: 3px;
}

.panel {
    background-color: #f8f9fa;
    border-width: 2px;
}

.panel-default > .panel-heading {
    color: white;
    background-color: #003566;
    border-color: #ffc300;
}

.panel.panel-default img {
    max-width: 100%;
    width: 50px;
}

.stages-wrapper .stage .stage-panel .fixture-panel {
    padding: 0 2px;
}

.stage-panel:nth-child(odd) .stage-panel-footer .line-1 {
    height: 15px;
    width: 2px;
    margin: auto;
    border-left: 2px solid #003566;
    box-sizing: content-box;
}
.stage-panel .stage-panel-footer .gap {
    width: 100%;
    height: 4rem;
}
.stage-panel .stage-panel-footer .gap-up {
    width: 100%;
    height: 25%;
    display: flex;
}
.stage-panel:nth-child(odd) .stage-panel-footer .gap-up-left {
    height: 100%;
    width: 100%;
}
.stage-panel:nth-child(odd) .stage-panel-footer .gap-up-right {
    height: 100%;
    width: 100%;
    border-left: 2px solid #003566;
    border-bottom: 2px solid #003566;
}
.stage-panel:nth-child(even) .stage-panel-footer .gap-up-left {
    height: 100%;
    width: 100%;
    border-right: 2px solid #003566;
    border-bottom: 2px solid #003566;
}
.stage-panel:nth-child(even) .stage-panel-footer .gap-up-right {
    height: 100%;
    width: 100%;
}
.stage-panel:nth-child(odd) .stage-panel-footer .gap-down {
    width: 100%;
    height: 75%;
}
.stage-panel:nth-child(even) .stage-panel-footer .gap-down {
    width: 100%;
    height: 75%;
    display: flex;
    border-left: 2px solid #003566;
}

.stage-row:after,
.stage:after {
    content: "";
    display: block;
    clear: both;
}

.day-label {
    font-weight: 600;
}

.stage-label-short {
    display: none;
}

.panel-score {
    text-align: center;
    padding: 5px 3px 5px;
    border-top: 1px solid #d3d3d3;
    background-color: #ffd30c;
}

@media only screen and (max-width: 990px) {
    .stage.round-of-sixteen .stage-panel .panel-heading {
        font-size: 10px;
        padding: 5px 0px 5px 0px;
    }

    .date-label {
        display: block;
    }

    .stage-label {
        display: none;
    }

    .stage-label-short {
        display: block;
    }
}
