.finalOthersResultsDiv {
    display: flex;
}

.finalOthersResults {
    float: left;
    margin-right: 1rem;
}

.jjj {
    float: right;
}
