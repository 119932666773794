.show-counter {
    padding: 0.5rem;
    justify-content: center;
    display: flex;
}

.show-counter .countdown {
    font-size: 2rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 1rem;
}

#cardCountdown {
    background-color: #003566 !important;
    color: white;
}

#countdownDivider {
    background-color: #ffc300 !important;
    color: white;
}

@media only screen and (max-width: 990px) {
    .show-counter .countdown {
        font-size: 1.5rem;
    }

    .show-counter .countdown > span {
        font-size: 0.75rem;
    }
}
