/* Text Alignment */

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

/* Font Modifications */

.bold {
    font-weight: bold;
    color: #003566;
}

.color-red {
    color: rgb(206, 0, 0);
}

tr.bg-color-yellow td {
    background-color: rgba(255, 195, 0, 0.25);
    border-color: rgba(255, 195, 0, 0.5);
}

/* Specific */

.flag--left-aligned {
    height: 40px;
    padding-right: 10px;
}

.flag--right-aligned {
    height: 40px;
    padding-left: 10px;
}

.team-block {
    display: inline-block;
}

.stageTotalPoints {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    font-size: 14px;
    opacity: 60%;
    font-weight: 600;
}

.nav-link {
    color: grey;
}

.nav-link.active {
    color: white;
    background-color: #003566 !important;
}

.navbar-nav .nav-link {
    padding: 10px !important;
}

.nav-link:hover {
    text-decoration: none;
    color: #003566;
}

.dropdown-item:active {
    background-color: transparent !important;
}

#betsAccordion .accordion-body {
    padding: 0.5rem 0 0.5rem 0;
}

.accordion-button:not(.collapsed) {
    color: white !important;
    background-color: #003566 !important;
}

.accordion-button:focus {
    box-shadow: none !important;
    border-color: rgba(0, 0, 0, 0.125) !important;
}

.carousel-indicators [data-bs-target] {
    background-color: #003566;
    width: 5rem;
}

.carousel-indicators {
    position: relative;
}

/* IDs  */

#betsAccordion {
    padding-bottom: 1rem;
}

#navbar-stage-selection-dropdown {
    display: none;
}

#selectedStageDisplay {
    display: none;
}

#selectedBettorTotalPoints {
    text-align: left;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

#navbar__logo-avisporra-text {
    padding-left: 10px;
}

#speedometer {
    width: 20em;
    margin: auto;
}

#prizepool {
    font-weight: bold;
    font-style: italic;
    font-size: 2.5em;
    /* margin: 57.5px 0; */
    color: #003566;
    padding: 0.5rem;
}

/* Todays Matches Table */

#matches__stage {
    width: 6rem;
}

/* Matches Table */

#matches__live-ticker {
    width: 3rem;
}

#matches__matchdetails-bets,
#matches__matchdetails,
#matches__teamname-home,
#matches__teamname-away {
    width: 14rem;
}

/* #matches__bet,  */
#matches__points {
    width: 5rem;
}

#matches__score {
    width: 5rem;
}

/* Group Classification Table */

#group-classification__position,
#group-classification__goal-difference,
#group-classification__teampoints,
#group-classification__points {
    width: 5rem;
}

#group-classification__teamname {
    width: 14rem;
}

#group-classification__joint {
    width: 10rem;
}

/* Round Pass Table */

#roundpass__description,
#roundpass__teamname {
    width: 14rem;
}

#roundpass__points {
    width: 5rem;
}

/* Confrontation Table */

#confrontation__description,
#confrontation__teamname,
#confrontation__bet {
    width: 14rem;
}

#confrontation__points {
    width: 5rem;
}

/* Tournament Classification Table */

#tournament-classification__description,
#tournament-classification__teamname {
    width: 14rem;
}

#tournament-classification__points {
    width: 5rem;
}

/* Other Table */

#other__description,
#other__teamname {
    width: 14rem;
}

#other__points {
    width: 5rem;
}

#matches__matchdetails-stage,
#matches__matchdetails-time {
    display: none;
}

@media only screen and (max-width: 1200px) {
    #matches__matchdetails {
        width: 12rem;
    }

    #matches__teamname-home,
    #matches__teamname-away {
        width: 12rem;
    }

    #matches__placeholder {
        width: 2rem;
    }

    #group-classification__joint {
        width: 5rem;
    }
}

@media only screen and (max-width: 990px) {
    /* General */

    .team-block {
        display: block;
    }

    .btn-avisporra {
        width: 100%;
    }

    /* Specific */

    .flag--left-aligned,
    .flag--right-aligned {
        padding: 0;
    }

    .stageTotalPoints {
        display: none;
    }

    .navbar-link {
        padding-left: 1rem;
        text-align: center;
    }

    .navbar-nav .nav-link {
        padding-left: 1rem !important;
        text-align: center;
    }

    .navbar-nav .dropdown-menu {
        margin: 0 0 1rem 0;
        background-color: transparent;
        border: 0;
        /* --bs-dropdown-divider-bg: transparent;
        --bs-dropdown-divider-margin-y: none; */
        --bs-dropdown-divider-length: 10px;
        text-align: center;
    }

    .navbar-nav .dropdown-menu hr {
        width: 10rem;
        /* margin: auto; */
        margin: 0.2rem auto 0.2rem auto;
    }

    #navbar-stage-selection-dropdown .nav-link {
        color: white !important;
        background-color: #003566 !important;
        text-align: center;
    }

    #pointsDistributionStageHeading {
        display: none;
    }

    .dropdown-item.active {
        color: white !important;
        background-color: #003566 !important;
    }

    .pointsDetails {
        display: none;
    }

    .weekday-label {
        display: block;
    }

    /* Font Sizes */

    td {
        font-size: 14px;
    }

    .fs-small {
        font-size: 12px;
    }

    .fs-large {
        font-size: 17px;
    }

    /* IDs  */

    #navbar__logo-avisporra {
        height: 3.5rem;
    }

    #navbar__logo-avisporra-text {
        height: 3.5rem;
    }

    #navbar__logo-championship {
        display: none;
    }

    #navbar div a {
        padding-left: 1rem;
    }

    .navbar-light .navbar-toggler {
        margin-right: 1.5rem;
    }

    #navbar-stage-selection {
        font-size: 10px;
        display: none;
        padding-bottom: 1rem;
    }

    #navbar-stage-selection-dropdown {
        display: block;
        padding-bottom: 1rem;
    }

    #selectedStageDisplay {
        text-align: center;
        font-weight: 500;
        padding-bottom: 1rem;
        display: block;
    }

    #languagesSelection {
        margin: 0;
        padding-left: 0.5rem;
        text-align: center;
    }

    #rankingContainer {
        width: 100%;
    }

    #selectedBettorTotalPoints {
        text-align: center;
    }

    #rankingBettorName {
        max-width: 8rem;
    }

    /* Matches Table */

    #matches__matchdetails-bets,
    #matches__stage,
    #matches__live-ticker,
    #matches__placeholder {
        display: none;
    }

    #matches__matchdetails {
        font-size: 14px;
    }

    #matches__matchdetails-datetime,
    #matches__matchdetails-venue {
        display: none;
    }

    #matches__matchdetails-time,
    #matches__matchdetails-stage {
        display: inline-block;
    }

    #matches__teamname-home,
    #matches__teamname-away,
    #matches__score {
        text-align: center;
    }

    #matches__score {
        text-align: center;
    }

    /* Group Classification Table */

    #group-classification__goal-difference,
    #group-classification__teampoints,
    #group-classification__joint {
        display: none;
    }

    #group-classification__teamname,
    #group-classification__bet {
        text-align: center;
        width: auto;
    }

    /* Round Pass Table */

    #roundpass__description {
        display: none;
    }

    #roundpass__teamname,
    #roundpass__bet {
        text-align: center;
        width: auto;
    }

    /* Confrontation Table */

    #confrontation__description {
        display: none;
    }

    #confrontation__bet,
    #confrontation__teamname {
        text-align: center;
        /* width: auto */
    }

    /* Tournament Classification Table */

    #tournament-classification__description {
        display: none;
    }

    #tournament-classification__teamname,
    #tournament-classification__bet {
        text-align: center;
        width: auto;
    }

    /* Other Table */

    #other__description {
        display: none;
    }

    #other__teamname,
    #other__bet {
        text-align: center;
        width: auto;
    }

    #speedometer {
        width: 100%;
    }

    #prizepool {
        font-weight: bold;
        font-style: italic;
        font-size: 2em;
        /* margin: 57.5px 0; */
        color: #003566;
        padding: 1rem;
        height: fit-content;
    }

    #payoutShare {
        display: none;
    }
}

@media only screen and (max-width: 360px) {
    #navbar__logo-avisporra {
        height: 3rem;
    }

    #navbar__logo-avisporra-text {
        height: 3rem;
    }
}

/* ----- */

.totalpoints {
    font-weight: bold;
}

#bettorSel {
    margin-bottom: 20px;
}

#groups {
    padding-bottom: 40px;
    padding-top: 20px;
}

#groups2 {
    padding-bottom: 40px;
}
